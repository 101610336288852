.vanabut  {
    
    color: black;
  }
  
  .vanabut input:checked {
    background-color: #22c55e; /* bg-green-500 */
  }
  
  .vanabut input:checked ~ span:last-child {
    --tw-translate-x: 3rem; /* translate-x-7 */
  }