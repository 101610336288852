

/* @media (min-width: 768px) {
    
    .Appcik {
        
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        border-radius: 0px 0px 10px 10px;
        -webkit-font-smoothing: antialiased;
        
    }

    .containercik {
        width: 100%;
        height: 65%;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        background: linear-gradient(to right bottom, #0161c2, #04a7f9, #4bc4f7);
        color: rgb(255, 255, 255);
    }

    .today {
        margin: 0%;
        height: 40%;
        width: 100%;
        display: flex;
    }

    .todays-details {
        
       
        align-items: baseline;
        justify-content: space-evenly;
        display: flex;
        font-size: 200%;
        text-transform: capitalize;
    }

    .deneme {
        display: flex;
        align-items: center;
    }

    .dayDetails {
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 6rem;
        width: 6rem;
        margin: 0% 3% 1% 3%;
        padding: 3%;
        font-size: 45%;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.166);
        border-radius: 15px;
        color: rgba(255, 255, 255, 0.846);
    }

    .dayDetails p {
        text-align: center;
        margin: 3%;
    }

    .sonuc {
        font-size: 200%;
    }

    .details-img {
        align-items: center;
        justify-content: center;
        width: 22%;
        display: flex;
        text-transform: lowercase;
    }

    .det {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0%;
    }

    .w-img {
        width: 26%;
        height: 56%;
        display: flex;
    }

    .container-left {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        justify-content: center;
        -webkit-box-pack: center;
        width: 38%;
    }

    .container-right {
        padding-top: 1rem;
        padding-right: 1rem;
        width: 65%;
    }

    .hourly-panel {
        width: 100%;
       
        padding-bottom: 0.25rem;
        color: rgba(255, 255, 255, 0.829);
        
        align-items: flex-start;
        display: flex;
        border-radius: 0px 0px 10px 10px;
        font-family: 'Poppins', sans-serif;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .hourly {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
    }

    .hourly-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1 / 1;
        width: 45%;
        height: 45%;
        padding: 1%;
    }

    .hourly p {
        margin: 5%;
    }

    .days-panel {
        display: flex;
        justify-content: center;
        align-items: baseline;
        width: 100%;
        height: 35%;
        padding-top: 0.25rem;
        color: rgba(0, 0, 0, 0.574);
        background-color: rgb(255, 255, 255);
        border-bottom: 1px solid rgb(255, 255, 255);
        border-radius: 0px 0px 10px 10px;
    }

    .location p {
        font-size: 1.25rem;
        margin: 0px;
    }

    .history p {
        font-size: 90%;
        margin: 3px 0px 3px 0px;
    }

    .description p {
        text-transform: capitalize;
        width: 120%;
        font-size: 100%;
        padding-left: 5%;
        margin: 3px 0px 0px 0px;
        font-weight: 550;
    }

    .temp p {
        font-size: 170%;
        margin: 3px 0px 0px 0px;
    }

    .weekDay {
        display: flex;
        align-items: baseline;
        justify-content: center;
    }

    .title {
        display: none;
        font-family: 'Poppins', sans-serif;
        font-weight: 550;
        font-size: 100%;
        margin: 5px 0px 6px 12px;
    }

    .weekImg {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2%;
    }

    hr {
        margin: 0%;
        border: 1px solid rgba(255, 255, 255, 0.739);
        border-radius: 8px;
        width: 98%;
    }
} */

@media (max-width: 768px) {
    .Appcik {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        border-radius: 0px 0px 10px 10px;
        -webkit-font-smoothing: antialiased;
        ;
        
    }

    .containercik {
        width: 100%;
        height: 67%;
        /* background: linear-gradient(to right bottom, #0161c2, #04a7f9, #4bc4f7);
        color: rgb(255, 255, 255); */
        background: none;
        color: #454A73;

    }

    .today {
        height: 40%;
        width: 100%;
        display: flex;
        margin: 0%;
        padding: 0%;
    }

    .todays-details {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 30%;
        font-size: 24%;
        text-transform: capitalize;
    }

    .dayDetails {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 400%;
        font-weight: 600;
        height: 6rem;
        width: 7rem;
        padding-left:0.65rem;
        padding-right:0.65rem;
        background-color: none;
        /* background-color: rgba(0, 0, 0, 0.11); */
        border-radius: 15px;
        /* color: rgba(255, 255, 255, 0.846); */
        color: #454A73;
    }

    .dayDetails p {
        text-align: center;
        margin: 3%;
    }

    .sonuc {
        font-size: 150%;
        
    }

    .details-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18%;
        margin: 0%;
        padding: 0%;
        text-transform: lowercase;
    }

    .det {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0%;
        font-size: 100%;
        
    }

    .w-img {
        width: 28%;
        display: flex;
        margin: 0%;
        padding: 0%;
    }

    .container-left {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        justify-content: center;
        -webkit-box-pack: center;
        width: 40%;
        height: 100%;
        
    }

    .container-right {
        padding: 4%;
        width: 70%;
    }

    .hourly-panel {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: max-content;
        font-family: 'Poppins', sans-serif;
        font-size: 80%;
        font-weight: 600;
        padding-bottom: 2%;
        /* color: rgba(255, 255, 255, 0.829); */
        color: #454A73;
        border-radius: 0px 0px 10px 10px;
        
        
    }

    .hourly {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0px;
        padding: 0px 0px;
        text-align: center;
        text-transform: capitalize;
        
    }

    .hourly-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1 / 1;
        width: 40%;
        
    }

    .hourly p {
        margin: 6%;
    }

    .days-panel {
        height: 33%;
        padding-top: 2%;
        /* color: rgba(0, 0, 0, 0.574); */
        color: #454A73;
        /* background-color: rgb(255, 255, 255); */
        background-color: none;
        border-bottom: 1px solid rgb(255, 255, 255);
        border-radius: 0px 0px 10px 10px;
        

    }

    .location p {
        font-size: 1.755rem;
        margin: 0px;
       
    }

    .history p {
        font-size: 100%;
        margin: 3px 0px 3px 0px;
    }
    .deneme {
        display: flex;
        align-items: center;
        
    }s

    .description p {
        text-transform: capitalize;
        font-size: 100%;
        margin: 3px 0px 0px 0px;
    }

    .temp p {
        font-size: 200%;
        margin: 3px 0px 0px 0px;
    }

    .weekDay {
        display: flex;
        align-items: baseline;
        justify-content: center;
        
    }

    .title {
        display: none;
        font-family: 'Poppins', sans-serif;
        font-weight: 550;
        font-size: 100%;
        margin: 5px 0px 6px 13px;
    }

    .weekImg {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2%;
    }

    hr {
        margin: 0%;
        border: 1px solid rgba(255, 255, 255, 0.739);
        border-radius: 8px;
        width: 90%;
        
    }

}
@media (min-width: 768px) {
    .Appcik {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        border-radius: 0px 0px 10px 10px;
        -webkit-font-smoothing: antialiased;
        ;
        
    }

    .containercik {
        width: 100%;
        height: 67%;
        /* background: linear-gradient(to right bottom, #0161c2, #04a7f9, #4bc4f7);
        color: rgb(255, 255, 255); */
        background: none;
        color: #454A73;

    }

    .today {
        height: 40%;
        width: 100%;
        display: flex;
        margin: 0%;
        padding: 0%;
    }

    .todays-details {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 30%;
        font-size: 24%;
        text-transform: capitalize;
    }

    .dayDetails {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 400%;
        font-weight: 600;
        height: 6rem;
        width: 7rem;
        padding-left:0.65rem;
        padding-right:0.65rem;
        background-color: none;
        /* background-color: rgba(0, 0, 0, 0.11); */
        border-radius: 15px;
        /* color: rgba(255, 255, 255, 0.846); */
        color: #454A73;
    }

    .dayDetails p {
        text-align: center;
        margin: 3%;
    }

    .sonuc {
        font-size: 150%;
        
    }

    .details-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18%;
        margin: 0%;
        padding: 0%;
        text-transform: lowercase;
    }

    .det {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0%;
        font-size: 100%;
        
    }

    .w-img {
        width: 28%;
        display: flex;
        margin: 0%;
        padding: 0%;
    }

    .container-left {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        justify-content: center;
        -webkit-box-pack: center;
        width: 40%;
        height: 100%;
        
    }

    .container-right {
        padding: 4%;
        width: 70%;
    }

    .hourly-panel {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: max-content;
        font-family: 'Poppins', sans-serif;
        font-size: 80%;
        font-weight: 600;
        padding-bottom: 2%;
        /* color: rgba(255, 255, 255, 0.829); */
        color: #454A73;
        border-radius: 0px 0px 10px 10px;
        
        
    }

    .hourly {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0px;
        padding: 0px 0px;
        text-align: center;
        text-transform: capitalize;
        
    }

    .hourly-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1 / 1;
        width: 40%;
        
    }

    .hourly p {
        margin: 6%;
    }

    .days-panel {
        height: 33%;
        padding-top: 2%;
        /* color: rgba(0, 0, 0, 0.574); */
        color: #454A73;
        /* background-color: rgb(255, 255, 255); */
        background-color: none;
        border-bottom: 1px solid rgb(255, 255, 255);
        border-radius: 0px 0px 10px 10px;
        

    }

    .location p {
        font-size: 1.755rem;
        margin: 0px;
       
    }

    .history p {
        font-size: 100%;
        margin: 3px 0px 3px 0px;
    }
    .deneme {
        display: flex;
        align-items: center;
        
    }s

    .description p {
        text-transform: capitalize;
        font-size: 100%;
        margin: 3px 0px 0px 0px;
    }

    .temp p {
        font-size: 200%;
        margin: 3px 0px 0px 0px;
    }

    .weekDay {
        display: flex;
        align-items: baseline;
        justify-content: center;
        
    }

    .title {
        display: none;
        font-family: 'Poppins', sans-serif;
        font-weight: 550;
        font-size: 100%;
        margin: 5px 0px 6px 13px;
    }

    .weekImg {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2%;
    }

    hr {
        margin: 0%;
        border: 1px solid rgba(255, 255, 255, 0.739);
        border-radius: 8px;
        width: 90%;
        
    }

}